<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-10">
        <h1>Test Vuexy with Tryton RPC</h1>
        <hr><br>
        <alert
          v-if="showLogin"
          :message="loginmsg"
        />
        <input
          id="lid"
          type="hidden"
          name="lid"
        >
        <input
          id="lcode"
          type="hidden"
          name="lcode"
        >
        <input
          id="lgetpref"
          type="hidden"
          name="lgetpref"
        >
        <input
          id="lwizardid"
          type="hidden"
          name="lwizardid"
        >
        <input
          id="lcwizardid"
          type="hidden"
          name="lcwizardid"
        >
        <input
          id="wutoken"
          type="hidden"
          name="wutoken"
        >
        <input
          id="assignments"
          type="hidden"
          name="assignments"
        >
        <input
          id="event_time"
          type="hidden"
          name="event_time"
        >
        <table>
          <tr>
            <td><label>User:</label><input
              id="luser"
              type="text"
              name="luser"
              class="form-control"
              value="admin"
            ></td>
            <td><label>Password:</label><input
              id="lpw"
              type="password"
              name="lpw"
              class="form-control"
              value="admin"
            ></td>
          </tr>
          <tr>
            <td><label>WebUser Email:</label><input
              id="lwuser"
              type="text"
              name="lwuser"
              class="form-control"
              value="leandro@promentum.co.uk"
            ></td>
            <td><label>WebUser Password:</label><input
              id="lwpw"
              type="password"
              name="lwpw"
              class="form-control"
              value="LIUtxt100"
            ></td>
          </tr>
          <tr>
            <td><label>SiteId:</label><input
              id="lsiteid"
              type="tyext"
              name="lsiteid"
              class="form-control"
            ></td>
            <td><label>SiteRef:</label>
              <input
                id="lsiteref"
                type="tyext"
                name="lsiteref"
                class="form-control"
              ></td>
          </tr>
          <tr>
            <td><label>Latitude:</label><input
              id="llatitude"
              type="text"
              name="llatitude"
              class="form-control"
              value="53.31"
            ></td>
            <td><label>Longitude:</label><input
              id="llongitude"
              type="text"
              name="llongitude"
              class="form-control"
              value="-1.71"
            ></td>
          </tr>
        </table>
        <label>RoleName:</label>
        <input
          id="lrname"
          type="tyext"
          name="lrname"
          class="form-control"
        >
        <p />
        <alert
          v-if="showMessage"
          :message="message"
        />
        <br>
        <button
          type="button"
          class="btn btn-success btn-sm"
          @click="getVersion()"
        >
          Version
        </button>
        <button
          type="button"
          class="btn btn-warning btn-sm"
          @click="Login()"
        >
          Login
        </button>
        <button
          type="button"
          class="btn btn-warning btn-sm"
          @click="WebLogin()"
        >
          WebLogin
        </button>
        <button
          type="button"
          class="btn btn-danger btn-sm"
          @click="getUser()"
        >
          GetUserDetails(preferences)
        </button>
        <button
          type="button"
          class="btn btn-success btn-sm"
          @click="getSearchUser()"
        >
          SearchUser
        </button>
        <button
          type="button"
          class="btn btn-warning btn-sm"
          @click="getSearchSite()"
        >
          SearchSites
        </button>
        <button
          type="button"
          class="btn btn-danger btn-sm"
          @click="getSearchSiteById()"
        >
          SearchSiteById
        </button>
        <button
          type="button"
          class="btn btn-success btn-sm"
          @click="SaveSite()"
        >
          SaveSite
        </button>
        <button
          type="button"
          class="btn btn-warning btn-sm"
          @click="SearchRole()"
        >
          SearchRole
        </button>
        <button
          type="button"
          class="btn btn-danger btn-sm"
          @click="CreateRole()"
        >
          CreateRole
        </button>
        <button
          type="button"
          class="btn btn-success btn-sm"
          @click="SearchSiteAttendance()"
        >
          SearchSiteAtendance
        </button>
        <button
          type="button"
          class="btn btn-success btn-sm"
          @click="SearchSiteOperative()"
        >
          SearchSiteOperative
        </button>
        <button
          type="button"
          class="btn btn-warning btn-sm"
          @click="SearchSiteOperativeAssign()"
        >
          SearchSiteOperativeAssign
        </button>
        <button
          type="button"
          class="btn btn-danger btn-sm"
          @click="SearchSiteOperativeEvents()"
        >
          SearchSiteOperativeEvents
        </button>
        <button
          type="button"
          class="btn btn-warning btn-sm"
          @click="GetWebUserTokenCreate()"
        >
          GetWebUserToken(CreateWizard)
        </button>
        <button
          type="button"
          class="btn btn-danger btn-sm"
          @click="GetWebUserTokenExecute()"
        >
          GetWebUserToken(ExecuteWizard)
        </button>
        <button
          type="button"
          class="btn btn-success btn-sm"
          @click="GetWebUserTokenExecuteLogin()"
        >
          GetWebUserToken(Login)
        </button>
        <button
          type="button"
          class="btn btn-success btn-sm"
          @click="DeleteWebUserTokenWizard()"
        >
          WebUserTokenWizard(Delete)
        </button>
        <button
          type="button"
          class="btn btn-warning btn-sm"
          @click="CheckInCreate()"
        >
          CheckIn(CreateWizard)
        </button>
        <button
          type="button"
          class="btn btn-danger btn-sm"
          @click="CheckInExecute()"
        >
          CheckIn(ExecuteWizard)
        </button>
        <button
          type="button"
          class="btn btn-success btn-sm"
          @click="CheckInExecuteCheckIn()"
        >
          CheckIn(Execute-CheckIn)
        </button>
        <button
          type="button"
          class="btn btn-success btn-sm"
          @click="CheckInWizardDelete()"
        >
          CheckInWizard(delete)
        </button>
        </button>
        <button
          type="button"
          class="btn btn-warning btn-sm"
          @click="CheckOutCreate()"
        >
          CheckOut(CreateWizard)
        </button>
        <button
          type="button"
          class="btn btn-danger btn-sm"
          @click="CheckOutExecute()"
        >
          CheckOut(ExecuteWizard)
        </button>
        <button
          type="button"
          class="btn btn-success btn-sm"
          @click="CheckOutExecuteCheckIn()"
        >
          CheckOut(Execute-CheckOut)
        </button>
        <button
          type="button"
          class="btn btn-success btn-sm"
          @click="CheckOutWizardDelete()"
        >
          CheckOutWizard(delete)
        </button>
        <button
          type="button"
          class="btn btn-success btn-sm"
          @click="doWebLogin()"
        >
          Full weblogin
        </button>

        <br><br>
      </div>
    </div>

  </div>
</template>
<script>
import axios from 'axios'
import alert from '@/components/alert.vue'

export default {
  components: {
    alert,
  },
  data() {
    return {
      books: [],
      message: '',
      SITE_OPERATIVE_ID: 7, // if id does not exist, it returns response 400
      showMessage: false,
      showLogin: false,
    }
  },
  methods: {
    resetMessage() {
      this.message = ''
      this.showMessage = false
      this.showLogin = false
    },
    getVersion() {
      const path = 'http://localhost:8000/'
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
          'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS, post, get',
          'Access-Control-Allow-Credentials': 'true',
        },
      }
      const payload = {
        // jsonrpc: '2.0',
        id: '1',
        method: 'common.server.version',
        params: [],
      }
      axios.post(path, payload, config)
        .then(response => {
          this.message = response.data
          this.showMessage = true
        })
        .catch(error => {
          this.message = error
          this.showMessage = true
        })
    },
    Login() {
      const path = 'http://localhost:8000/procentral/'
      const user = document.getElementById('luser').value
      const pw = document.getElementById('lpw').value
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
          'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS, post, get',
          'Access-Control-Allow-Credentials': 'true',
        },
      }
      const payload = {
        // jsonrpc: '1.0',
        id: '1',
        method: 'common.db.login',
        params: [user, { password: pw }],
      }
      axios.post(path, payload, config)
        .then(response => {
          this.message = response.data.result
          const iterator = response.data.result.values()
          document.getElementById('lid').value = iterator.next().value
          document.getElementById('lcode').value = iterator.next().value
          this.showMessage = true
          this.loginmsg = 'Logged In'
          this.showLogin = true
        })
        .catch(error => {
          this.message = error
          this.showMessage = true
        })
    },
    async WebLogin() {
      const path = 'http://localhost:8000/procentral/'
      const web_user_email = document.getElementById('lwuser').value
      const web_pw = document.getElementById('lwpw').value
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
          'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS, post, get',
          'Access-Control-Allow-Credentials': 'true',
        },
      }
      const payload = {
        // jsonrpc: '1.0',
        id: '1',
        method: 'common.db.login',
        params: ['web_user', { email: web_user_email, password: web_pw }], // This way you Login to proCentral with the web user details, so no need to hardcode anything on VueJS
      }
      axios.post(path, payload, config)
        .then(response => {
          console.log('web login')
          console.log(response)
          this.message = response //.data //.result
          const iterator = response.data.result.values()
          document.getElementById('lid').value = iterator.next().value
          document.getElementById('lcode').value = iterator.next().value
          this.showMessage = true
          this.loginmsg = 'Logged In'
          this.showLogin = true
        })
        .catch(error => {
          this.message = error
          this.showMessage = true
        })
    },
    async getUser() {
      const path = 'http://localhost:8000/procentral/'
      const loginid = document.getElementById('lid').value
      const logincode = document.getElementById('lcode').value
      const sAuth1 = String('admin:') + String(loginid) + String(':') + String(logincode)
      const sAuth = String(btoa(sAuth1))
      const sessionAuth = String('Session ') + sAuth
      const config = {
        headers: {
          'Content-Type': 'application/json',
          // 'Access-Control-Allow-Origin': '*',
          // 'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
          // 'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS, post, get',
          // 'Access-Control-Allow-Credentials': 'true',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: sessionAuth,
        },
      }
      const payload = {
        // jsonrpc: '1.0',
        id: '1',
        method: 'model.res.user.get_preferences',
        params: [[], {}],
      }
      axios.post(path, payload, config)
        .then(response => {
          this.message = response.data
          this.showMessage = true
          document.getElementById('lgetpref').value = JSON.stringify(response.data.result)
          const lgetpref = document.getElementById('lgetpref').value
          // var mergedObj = [lgetpref, {web_user_session: '12345'}];
          // JSON.parse(lgetpref).web_user_session = '12345';
          // this.message = lgetpref;
        })
        .catch(error => {
          this.message = error
          this.showMessage = true
        })
    },
    getSearchUser() {
      const path = 'http://localhost:8000/procentral/'
      const user = document.getElementById('luser').value
      const loginid = document.getElementById('lid').value
      const logincode = document.getElementById('lcode').value
      const sAuth1 = String('admin:') + String(loginid) + String(':') + String(logincode)
      const sAuth = String(btoa(sAuth1))
      const sessionAuth = String('Session ') + sAuth
      const config = {
        headers: {
          'Content-Type': 'application/json',
          // 'Access-Control-Allow-Origin': '*',
          // 'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
          // 'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS, post, get',
          // 'Access-Control-Allow-Credentials': 'true',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: sessionAuth,
        },
      }
      const payload = {
        // jsonrpc: '1.0',
        id: '1',
        method: 'model.res.user.search_read',
        params: [[['login', '=', user]], null, null, null, ['name', 'email'], {}],
      }
      axios.post(path, payload, config)
        .then(response => {
          this.message = response.data
          this.showMessage = true
        })
        .catch(error => {
          this.message = error
          this.showMessage = true
        })
    },
    getSearchSite() {
      const path = 'http://localhost:8000/procentral/'
      const loginid = document.getElementById('lid').value
      const logincode = document.getElementById('lcode').value
      const sAuth1 = String('admin:') + String(loginid) + String(':') + String(logincode)
      const sAuth = String(btoa(sAuth1))
      const sessionAuth = String('Session ') + sAuth
      const config = {
        headers: {
          'Content-Type': 'application/json',
          // 'Access-Control-Allow-Origin': '*',
          // 'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
          // 'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS, post, get',
          // 'Access-Control-Allow-Credentials': 'true',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: sessionAuth,
        },
      }
      const payload = {
        // jsonrpc: '1.0',
        id: '1',
        method: 'model.site.search_read',
        params: [[], {}], // [] or ['id', '=', 51] or [['id', '=', 51]], null, null, null, ['name']
      }
      axios.post(path, payload, config)
        .then(response => {
          this.message = response.data
          this.showMessage = true
        })
        .catch(error => {
          this.message = error
          this.showMessage = true
        })
    },
    getSearchSiteById() {
      const path = 'http://localhost:8000/procentral/'
      const siteid = document.getElementById('lsiteid').value
      const loginid = document.getElementById('lid').value
      const logincode = document.getElementById('lcode').value
      const sAuth1 = String('admin:') + String(loginid) + String(':') + String(logincode)
      const sAuth = String(btoa(sAuth1))
      const sessionAuth = String('Session ') + sAuth
      const config = {
        headers: {
          'Content-Type': 'application/json',
          // 'Access-Control-Allow-Origin': '*',
          // 'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
          // 'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS, post, get',
          // 'Access-Control-Allow-Credentials': 'true',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: sessionAuth,
        },
      }
      const payload = {
        // jsonrpc: '1.0',
        id: '1',
        method: 'model.site.search_read',
        params: [[['id', '=', siteid]], null, null, null, ['name', 'address.street', 'address.postal_code', 'address.party.name', 'reference', 'start_date', 'end_date'], {}], // [] or ['id', '=', 51] or [['id', '=', 51]], null, null, null, ['name']
      }
      axios.post(path, payload, config)
        .then(response => {
          this.message = response.data
          this.showMessage = true
        })
        .catch(error => {
          this.message = error
          this.showMessage = true
        })
    },
    SaveSite() {
      const path = 'http://localhost:8000/procentral/'
      const siteid = document.getElementById('lsiteid').value
      const siteref = document.getElementById('lsiteref').value
      const loginid = document.getElementById('lid').value
      const logincode = document.getElementById('lcode').value
      const sAuth1 = String('admin:') + String(loginid) + String(':') + String(logincode)
      const sAuth = String(btoa(sAuth1))
      const sessionAuth = String('Session ') + sAuth
      const config = {
        headers: {
          'Content-Type': 'application/json',
          // 'Access-Control-Allow-Origin': '*',
          // 'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
          // 'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS, post, get',
          // 'Access-Control-Allow-Credentials': 'true',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: sessionAuth,
        },
      }
      const payload = {
        // jsonrpc: '1.0',
        id: '1',
        method: 'model.site.write',
        params: [[siteid], { reference: siteref }, {}],
      }
      axios.post(path, payload, config)
        .then(() => {
          this.message = 'Data saved'
          this.showMessage = true
        })
        .catch(error => {
          this.message = error
          this.showMessage = true
        })
    },
    SearchRole() {
      const path = 'http://localhost:8000/procentral/'
      const loginid = document.getElementById('lid').value
      const logincode = document.getElementById('lcode').value
      const sAuth1 = String('admin:') + String(loginid) + String(':') + String(logincode)
      const sAuth = String(btoa(sAuth1))
      const sessionAuth = String('Session ') + sAuth
      const config = {
        headers: {
          'Content-Type': 'application/json',
          // 'Access-Control-Allow-Origin': '*',
          // 'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
          // 'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS, post, get',
          // 'Access-Control-Allow-Credentials': 'true',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: sessionAuth,
        },
      }
      const payload = {
        // jsonrpc: '1.0',
        id: '1',
        method: 'model.site.role.search_read',
        params: [[], {}],
      }
      axios.post(path, payload, config)
        .then(response => {
          this.message = response.data
          this.showMessage = true
        })
        .catch(error => {
          this.message = error
          this.showMessage = true
        })
    },
    CreateRole() {
      const path = 'http://localhost:8000/procentral/'
      const rolename = document.getElementById('lrname').value
      const loginid = document.getElementById('lid').value
      const logincode = document.getElementById('lcode').value
      const sAuth1 = String('admin:') + String(loginid) + String(':') + String(logincode)
      const sAuth = String(btoa(sAuth1))
      const sessionAuth = String('Session ') + sAuth
      const config = {
        headers: {
          'Content-Type': 'application/json',
          // 'Access-Control-Allow-Origin': '*',
          // 'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
          // 'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS, post, get',
          // 'Access-Control-Allow-Credentials': 'true',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: sessionAuth,
        },
      }
      const payload = {
        // jsonrpc: '1.0',
        id: '1',
        method: 'model.site.role.create',
        params: [[{ name: rolename }], {}],
      }
      axios.post(path, payload, config)
        .then(response => {
          this.message = response.data
          this.showMessage = true
        })
        .catch(error => {
          this.message = error
          this.showMessage = true
        })
    },
    SearchSiteAttendance() {
      const path = 'http://localhost:8000/procentral/'
      const loginid = document.getElementById('lid').value
      const logincode = document.getElementById('lcode').value
      const sAuth1 = String('admin:') + String(loginid) + String(':') + String(logincode)
      const sAuth = String(btoa(sAuth1))
      const sessionAuth = String('Session ') + sAuth
      const config = {
        headers: {
          'Content-Type': 'application/json',
          // 'Access-Control-Allow-Origin': '*',
          // 'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
          // 'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS, post, get',
          // 'Access-Control-Allow-Credentials': 'true',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: sessionAuth,
        },
      }
      const payload = {
        // jsonrpc: '1.0',
        id: '1',
        method: 'model.project.attendance.search_read',
        params: [[['operative.id', '=', 1]], null, null, null, ['id', 'site.id', 'site.name', 'site.address.id', 'site.address.street', 'site.address.postal_code', 'site.address.party.id', 'site.address.party.name', 'operative.id', 'operative.name'], {}],
      }
      axios.post(path, payload, config)
        .then(response => {
          this.message = response.data
          this.showMessage = true
        })
        .catch(error => {
          this.message = error
          this.showMessage = true
        })
    },
    SearchSiteOperative() {
      // function to get all sites that needs to checkin and checkout (maybe run SearchSiteOperativeEvents, before this one, to see if is already done - checkedIn)
      const path = 'http://localhost:8000/procentral/'
      const lgetpref = document.getElementById('lgetpref').value
      const wutoken = document.getElementById('wutoken').value
      const loginid = document.getElementById('lid').value
      const logincode = document.getElementById('lcode').value
      const sAuth1 = String('admin:') + String(loginid) + String(':') + String(logincode)
      const sAuth = String(btoa(sAuth1))
      const sessionAuth = String('Session ') + sAuth
      const config = {
        headers: {
          'Content-Type': 'application/json',
          // 'Access-Control-Allow-Origin': '*',
          // 'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
          // 'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS, post, get',
          // 'Access-Control-Allow-Credentials': 'true',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: sessionAuth,
        },
      }
      const payload = {
        // jsonrpc: '1.0',
        id: '1',
        method: 'model.site.operative.search_read',
        params: [[['start_date', '=', {
          __class__: 'date', year: 2021, month: 12, day: 17,
        }]], null, null, null, ['operative.id', 'operative.name', 'site.id', 'site.name', 'site.main_address', 'site.postal_code', 'site.latitude', 'site.longitude', 'site.contact.type', 'site.contact.value', 'site.contact.name', 'start_date', 'end_date', 'roles.name', 'site.number'], { web_user_session: wutoken }], // [[], {'web_user_session': wutoken}], // [[], null, null, null, ['id', 'site.id', 'site.name', 'site.address.id', 'site.address.street', 'site.address.postal_code', 'site.address.party.id', 'site.address.party.name', 'operative.id', 'operative.name'], {lgetpref}],
      }
      axios.post(path, payload, config)
        .then(response => {
          this.message = response.data
          // this.message = wutoken;
          this.showMessage = true
        })
        .catch(error => {
          this.message = error
          this.showMessage = true
        })
    },
    SearchSiteOperativeAssign() {
      // function to get all sites that needs to checkin and checkout (maybe run SearchSiteOperativeEvents, before this one, to see if is already done - checkedIn)
      const path = 'http://localhost:8000/procentral/'
      const assign_array = document.getElementById('assignments').value
      const assignment_id = assign_array[0] // testing with first one obtained
      const lgetpref = document.getElementById('lgetpref').value
      const wutoken = document.getElementById('wutoken').value
      const loginid = document.getElementById('lid').value
      const logincode = document.getElementById('lcode').value
      const sAuth1 = String('admin:') + String(loginid) + String(':') + String(logincode)
      const sAuth = String(btoa(sAuth1))
      const sessionAuth = String('Session ') + sAuth
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: sessionAuth,
        },
      }
      const payload = {
        id: '1',
        method: 'model.site.operative.assignment.search_read',
        params: [[['id', '=', assignment_id]], null, null, null, ['operative.id', 'operative.rec_name', 'operative_name', 'site_name', 'site_main_address', 'site_postal_code', 'site_latitude', 'site_longitude', 'contact_name', 'contact_value', 'date', 'role_name', 'signed_in', 'signed_out', 'state', 'number', 'site_opening_time', 'site_closing_time', 'site_operative', 'site_number'], { web_user_session: wutoken }],
      }
      axios.post(path, payload, config)
        .then(response => {
          console.log('assign: ', response.data)
          this.message = response.data // response.data
          const iterator = response.data.result.values()
          const json_resp = iterator.next().value
          this.SITE_OPERATIVE_ID = json_resp.site_operative // we can use this value, or we can use the one obtain in the checkin (start)
          this.showMessage = true
        })
        .catch(error => {
          console.log('assign error: ', error)
          this.message = error
          this.showMessage = true
        })
    },
    SearchSiteOperativeEvents() {
      // function to see if has already checked in or checked out
      const path = 'http://localhost:8000/procentral/'
      const lgetpref = document.getElementById('lgetpref').value
      const wutoken = document.getElementById('wutoken').value
      const loginid = document.getElementById('lid').value
      const logincode = document.getElementById('lcode').value
      const sAuth1 = String('admin:') + String(loginid) + String(':') + String(logincode)
      const sAuth = String(btoa(sAuth1))
      const sessionAuth = String('Session ') + sAuth
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: sessionAuth,
        },
      }
      const payload = {
        // jsonrpc: '1.0',
        id: '1',
        method: 'model.project.attendance.event.search_read',
        params: [[['date', '=', {
          __class__: 'date', year: 2021, month: 12, day: 17,
        }]], null, null, null, ['type', 'date', 'device', 'operative.id', 'operative.name', 'site.id', 'site.name'], { web_user_session: wutoken }],
        // Maybe we filter by operative.id, instead of date
        // params: [[['operative.id', '=', 7]], null, null, null, ['type', 'site', 'date', 'device', 'operative.id', 'operative.name'], {'web_user_session': wutoken}],
        // Type: can be In or Out
      }
      axios.post(path, payload, config)
        .then(response => {
          console.log(response.data)
          this.message = response.data
          this.showMessage = true
        })
        .catch(error => {
          this.message = error
          this.showMessage = true
        })
    },
    GetWebUserTokenCreate() {
      const path = 'http://localhost:8000/procentral/'
      const lgetpref = document.getElementById('lgetpref').value
      const loginid = document.getElementById('lid').value
      const logincode = document.getElementById('lcode').value
      const sAuth1 = String('admin:') + String(loginid) + String(':') + String(logincode)
      const sAuth = String(btoa(sAuth1))
      const sessionAuth = String('Session ') + sAuth
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: sessionAuth,
        },
      }
      const payload = {
        // jsonrpc: '1.0',
        id: '1',
        method: 'wizard.web.user.login.create',
        params: [{ lgetpref }],
      }
      axios.post(path, payload, config)
        .then(response => {
          console.log('web token create')
          console.log(response)
          this.message = response.data
          this.showMessage = true
          const iterator = response.data.result.values()
          document.getElementById('lwizardid').value = iterator.next().value
        })
        .catch(error => {
          this.message = error
          this.showMessage = true
        })
    },
    GetWebUserTokenExecute() {
      const path = 'http://localhost:8000/procentral/'
      const lgetpref = document.getElementById('lgetpref').value
      const lwizardid = document.getElementById('lwizardid').value
      const loginid = document.getElementById('lid').value
      const logincode = document.getElementById('lcode').value
      const sAuth1 = String('admin:') + String(loginid) + String(':') + String(logincode)
      const sAuth = String(btoa(sAuth1))
      const sessionAuth = String('Session ') + sAuth
      const config = {
        headers: {
          'Content-Type': 'application/json',
          // 'Access-Control-Allow-Origin': '*',
          // 'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
          // 'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS, post, get',
          // 'Access-Control-Allow-Credentials': 'true',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: sessionAuth,
        },
      }
      const payload = {
        // jsonrpc: '1.0',
        id: '1',
        method: 'wizard.web.user.login.execute',
        params: [lwizardid, {}, 'start', { lgetpref }],
      }
      axios.post(path, payload, config)
        .then(response => {
          console.log('web token execute')
          console.log(response)
          this.message = response.data
          this.showMessage = true
        })
        .catch(error => {
          this.message = error
          this.showMessage = true
        })
    },
    GetWebUserTokenExecuteLogin() {
      const path = 'http://localhost:8000/procentral/'
      const lgetpref = document.getElementById('lgetpref').value
      const lwizardid = document.getElementById('lwizardid').value
      const loginid = document.getElementById('lid').value
      const logincode = document.getElementById('lcode').value
      const wuser = document.getElementById('lwuser').value
      const wpw = document.getElementById('lwpw').value
      const sAuth1 = String('admin:') + String(loginid) + String(':') + String(logincode)
      const sAuth = String(btoa(sAuth1))
      const sessionAuth = String('Session ') + sAuth
      const config = {
        headers: {
          'Content-Type': 'application/json',
          // 'Access-Control-Allow-Origin': '*',
          // 'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
          // 'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS, post, get',
          // 'Access-Control-Allow-Credentials': 'true',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: sessionAuth,
        },
      }
      const payload = {
        // jsonrpc: '1.0',
        id: '1',
        method: 'wizard.web.user.login.execute',
        params: [lwizardid, { start: { email: wuser, password: wpw } }, 'login', { lgetpref }],
      }
      axios.post(path, payload, config)
        .then(response => {
          console.log('web token execute login')
          console.log(response)
          this.message = response.data
          this.showMessage = true
          document.getElementById('wutoken').value = response.data.result.view.defaults.token
          document.getElementById('assignments').value = response.data.result.view.defaults.assignments // this is an array!!! (all assignments that the operative has assigned for current_date)
        })
        .catch(error => {
          this.message = error
          this.showMessage = true
        })
    },
    DeleteWebUserTokenWizard() {
      const path = 'http://localhost:8000/procentral/'
      const lgetpref = document.getElementById('lgetpref').value
      const lwizardid = document.getElementById('lwizardid').value
      const loginid = document.getElementById('lid').value
      const logincode = document.getElementById('lcode').value
      const wuser = document.getElementById('lwuser').value
      const wpw = document.getElementById('lwpw').value
      const sAuth1 = String('admin:') + String(loginid) + String(':') + String(logincode)
      const sAuth = String(btoa(sAuth1))
      const sessionAuth = String('Session ') + sAuth
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: sessionAuth,
        },
      }
      const payload = {
        id: '1',
        method: 'wizard.web.user.login.delete',
        params: [lwizardid, { lgetpref }],
      }
      axios.post(path, payload, config)
        .then(response => {
          console.log('web delete wizard')
          console.log(response)
          this.message = response.data
          this.showMessage = true
        })
        .catch(error => {
          this.message = error
          this.showMessage = true
        })
    },
    CheckInCreate() {
      const path = 'http://localhost:8000/procentral/'
      const lgetpref = document.getElementById('lgetpref').value
      const loginid = document.getElementById('lid').value
      const logincode = document.getElementById('lcode').value
      // const wutoken = document.getElementById('wutoken').value;
      // let myJsonObject = JSON.parse(lgetpref);
      // myJsonObject['web_user_session'] = wutoken; //send always token
      const sAuth1 = String('admin:') + String(loginid) + String(':') + String(logincode)
      const sAuth = String(btoa(sAuth1))
      const sessionAuth = String('Session ') + sAuth
      const config = {
        headers: {
          'Content-Type': 'application/json',
          // 'Access-Control-Allow-Origin': '*',
          // 'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
          // 'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS, post, get',
          // 'Access-Control-Allow-Credentials': 'true',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: sessionAuth,
        },
      }
      const payload = {
        // jsonrpc: '1.0',
        id: '1',
        method: 'wizard.project.attendance.sign_in.create',
        params: [{ lgetpref }],
      }
      axios.post(path, payload, config)
        .then(response => {
          this.showMessage = true
          const iterator = response.data.result.values()
          document.getElementById('lcwizardid').value = iterator.next().value
          this.message = response.data
        })
        .catch(error => {
          this.message = error
          this.showMessage = true
        })
    },
    CheckInExecute() {
      const path = 'http://localhost:8000/procentral/'
      const assign_array = document.getElementById('assignments').value
      const assignment_id = assign_array[0]
      const lgetpref = document.getElementById('lgetpref').value
      const lcwizardid = document.getElementById('lcwizardid').value
      const loginid = document.getElementById('lid').value
      const logincode = document.getElementById('lcode').value
      const wutoken = document.getElementById('wutoken').value
      const sAuth1 = String('admin:') + String(loginid) + String(':') + String(logincode)
      const sAuth = String(btoa(sAuth1))
      const sessionAuth = String('Session ') + sAuth
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
          'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS, post, get',
          'Access-Control-Allow-Credentials': 'true',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: sessionAuth,
        },
      }
      const payload = {
        // jsonrpc: '1.0',
        id: '1',
        method: 'wizard.project.attendance.sign_in.execute',
        params: [lcwizardid, {}, 'start', {
          web_user_session: wutoken, active_id: assignment_id, active_ids: [assignment_id], active_model: 'site.operative.assignment',
        }],
      }
      axios.post(path, payload, config)
        .then(response => {
          this.message = response.data
          this.SITE_OPERATIVE_ID = response.data.result.view.defaults.site_operative
          // document.getElementById('event_time').value = response.data['result']['view']['defaults']['event_time']; //you can get a default value from here
          this.showMessage = true
        })
        .catch(error => {
          // console.log(error.request);
          this.message = error // + error.message + error.response + error.errorcode + error.response.data; // error.message; -> to see more details (helpfull on a error 400)
          this.showMessage = true
        })
    },
    CheckInExecuteCheckIn() {
      const path = 'http://localhost:8000/procentral/'
      const assign_array = document.getElementById('assignments').value
      const assignment_id = assign_array[0]
      const event_time = {
        __class__: 'datetime', year: 2022, month: 1, day: 14, hour: 15, minute: 21, second: 48, microsecond: 124112,
      } // document.getElementById('event_time').value;
      const llatitude = document.getElementById('llatitude').value
      const llongitude = document.getElementById('llongitude').value
      const lgetpref = document.getElementById('lgetpref').value
      const lcwizardid = document.getElementById('lcwizardid').value
      const loginid = document.getElementById('lid').value
      const logincode = document.getElementById('lcode').value
      const wuser = document.getElementById('lwuser').value
      const wpw = document.getElementById('lwpw').value
      const wutoken = document.getElementById('wutoken').value
      const sAuth1 = String('admin:') + String(loginid) + String(':') + String(logincode)
      const sAuth = String(btoa(sAuth1))
      const sessionAuth = String('Session ') + sAuth
      const config = {
        headers: {
          'Content-Type': 'application/json',
          // 'Access-Control-Allow-Origin': '*',
          // 'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
          // 'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS, post, get',
          // 'Access-Control-Allow-Credentials': 'true',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: sessionAuth,
        },
      }
      const payload = {
        // jsonrpc: '1.0',
        id: '1',
        method: 'wizard.project.attendance.sign_in.execute',
        params: [lcwizardid, {
          details: {
            site_operative: this.SITE_OPERATIVE_ID, latitude: llatitude, longitude: llongitude, device: 'TEST DEVICE', event_time,
          },
        }, 'create_event', {
          web_user_session: wutoken, active_id: assignment_id, active_ids: [assignment_id], active_model: 'site.operative.assignment',
        }],
      }
      axios.post(path, payload, config)
        .then(response => {
          this.message = response.data
          this.showMessage = true
        })
        .catch(error => {
          this.message = error.data
          this.showMessage = true
        })
    },
    CheckInWizardDelete() {
      const path = 'http://localhost:8000/procentral/'
      const lgetpref = document.getElementById('lgetpref').value
      const lcwizardid = document.getElementById('lcwizardid').value
      const loginid = document.getElementById('lid').value
      const logincode = document.getElementById('lcode').value
      const sAuth1 = String('admin:') + String(loginid) + String(':') + String(logincode)
      const sAuth = String(btoa(sAuth1))
      const sessionAuth = String('Session ') + sAuth
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: sessionAuth,
        },
      }
      const payload = {
        // jsonrpc: '1.0',
        id: '1',
        method: 'wizard.project.attendance.sign_in.delete',
        params: [lcwizardid, { lgetpref }],
      }
      axios.post(path, payload, config)
        .then(response => {
          this.message = response.data
          this.showMessage = true
        })
        .catch(error => {
          this.message = error.data
          this.showMessage = true
        })
    },
    CheckOutCreate() {
      const path = 'http://localhost:8000/procentral/'
      const lgetpref = document.getElementById('lgetpref').value
      const loginid = document.getElementById('lid').value
      const logincode = document.getElementById('lcode').value
      const sAuth1 = String('admin:') + String(loginid) + String(':') + String(logincode)
      const sAuth = String(btoa(sAuth1))
      const sessionAuth = String('Session ') + sAuth
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: sessionAuth,
        },
      }
      const payload = {
        // jsonrpc: '1.0',
        id: '1',
        method: 'wizard.project.attendance.sign_out.create',
        params: [{ lgetpref }],
      }
      axios.post(path, payload, config)
        .then(response => {
          this.showMessage = true
          const iterator = response.data.result.values()
          document.getElementById('lcwizardid').value = iterator.next().value
          this.message = response.data
        })
        .catch(error => {
          this.message = error
          this.showMessage = true
        })
    },
    CheckOutExecute() {
      const path = 'http://localhost:8000/procentral/'
      const assign_array = document.getElementById('assignments').value
      const assignment_id = assign_array[0]
      const lgetpref = document.getElementById('lgetpref').value
      const lcwizardid = document.getElementById('lcwizardid').value
      const loginid = document.getElementById('lid').value
      const logincode = document.getElementById('lcode').value
      const wutoken = document.getElementById('wutoken').value
      const sAuth1 = String('admin:') + String(loginid) + String(':') + String(logincode)
      const sAuth = String(btoa(sAuth1))
      const sessionAuth = String('Session ') + sAuth
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: sessionAuth,
        },
      }
      const payload = {
        // jsonrpc: '1.0',
        id: '1',
        method: 'wizard.project.attendance.sign_out.execute',
        params: [lcwizardid, {}, 'start', {
          web_user_session: wutoken, active_id: assignment_id, active_ids: [assignment_id], active_model: 'site.operative.assignment',
        }],
      }
      axios.post(path, payload, config)
        .then(response => {
          this.message = response.data
          this.SITE_OPERATIVE_ID = response.data.result.view.defaults.site_operative
          this.showMessage = true
        })
        .catch(error => {
          this.message = error // error.message; -> to see more details (helpfull on a error 400)
          this.showMessage = true
        })
    },
    CheckOutExecuteCheckIn() {
      const path = 'http://localhost:8000/procentral/'
      const assign_array = document.getElementById('assignments').value
      const assignment_id = assign_array[0]
      const event_time = {
        __class__: 'datetime', year: 2022, month: 1, day: 14, hour: 15, minute: 21, second: 48, microsecond: 124112,
      } // document.getElementById('event_time').value;
      const llatitude = document.getElementById('llatitude').value
      const llongitude = document.getElementById('llongitude').value
      const lgetpref = document.getElementById('lgetpref').value
      const lcwizardid = document.getElementById('lcwizardid').value
      const loginid = document.getElementById('lid').value
      const logincode = document.getElementById('lcode').value
      const wuser = document.getElementById('lwuser').value
      const wpw = document.getElementById('lwpw').value
      const wutoken = document.getElementById('wutoken').value
      const sAuth1 = String('admin:') + String(loginid) + String(':') + String(logincode)
      const sAuth = String(btoa(sAuth1))
      const sessionAuth = String('Session ') + sAuth
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: sessionAuth,
        },
      }
      const payload = {
        // jsonrpc: '1.0',
        id: '1',
        method: 'wizard.project.attendance.sign_out.execute',
        params: [lcwizardid, {
          details: {
            site_operative: this.SITE_OPERATIVE_ID, latitude: llatitude, longitude: llongitude, device: 'TEST DEVICE', event_time,
          },
        }, 'create_event', {
          web_user_session: wutoken, active_id: assignment_id, active_ids: [assignment_id], active_model: 'site.operative.assignment',
        }],
      }
      axios.post(path, payload, config)
        .then(response => {
          this.message = response.data
          this.showMessage = true
        })
        .catch(error => {
          this.message = error
          this.showMessage = true
        })
    },
    CheckOutWizardDelete() {
      const path = 'http://localhost:8000/procentral/'
      const lgetpref = document.getElementById('lgetpref').value
      const lcwizardid = document.getElementById('lcwizardid').value
      const loginid = document.getElementById('lid').value
      const logincode = document.getElementById('lcode').value
      const sAuth1 = String('admin:') + String(loginid) + String(':') + String(logincode)
      const sAuth = String(btoa(sAuth1))
      const sessionAuth = String('Session ') + sAuth
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: sessionAuth,
        },
      }
      const payload = {
        id: '1',
        method: 'wizard.project.attendance.sign_out.delete',
        params: [lcwizardid, { lgetpref }],
      }
      axios.post(path, payload, config)
        .then(response => {
          this.message = response.data
          this.showMessage = true
        })
        .catch(error => {
          this.message = error
          this.showMessage = true
        })
    },

    async doWebLogin() {
      try {
        console.log('web login hit')
        await this.WebLogin()
          .then(() => {
            console.log('get user hit')
            this.getUser()
              .then(() => {
                console.log('token create hit')
                this.GetWebUserTokenCreate()
                  .then(() => {
                    console.log('token execute hit')
                    this.GetWebUserTokenExecute()
                      .then(() => {
                        console.log('execute login hit')
                        this.GetWebUserTokenExecuteLogin()
                          .then(() => {
                            console.log('delete wizard hit')
                            this.DeleteWebUserTokenWizard()
                          })
                      })
                  })
              })
          })
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>
